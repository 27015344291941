import { useEffect, useRef, useState } from 'react';
import {
  Link,
  // NavLink,
  Outlet,
  useNavigate,
  useParams,
  // useParams,
} from 'react-router-dom';
import {
  Avatar,
  Drawer,
  Dropdown,
  MenuProps,
  Layout,
  InputRef,
  Button,
  App,
} from 'antd';
import useAuthStore from '@/store/authStore';
import {
  DownOutlined,
  UserOutlined,
  MenuOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import {
  CustomHeader,
  //  Navbar,
  PageWrap,
} from './styles';

import { useMediaQuery } from '@uidotdev/usehooks';
import MainMenu from './components/MainMenu';
import useSSE from '@/hooks/useSSE';
import { CheckMaskDocument, CheckMaskProject } from '@/types/document.type';
import { useQueryClient } from '@tanstack/react-query';

export const MainLayout = () => {
  const isMobile = useMediaQuery('only screen and (max-width : 992px)');
  const { Content, Sider } = Layout;
  const { logout, user } = useAuthStore();
  const [openDrawer, setOpenDrawer] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const { project_id } = useParams<{ project_id: string }>();
  const { data, sse } = useSSE(project_id, user?.access_token);
  const firstLoadDone = useRef<boolean>(false);
  const { message } = App.useApp();
  const queryClient = useQueryClient();
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const handleLogOut = () => {
    logout();
    navigate('/login', { replace: true });
  };

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Button type="text" icon={<LogoutOutlined />} onClick={handleLogOut}>
          Logout
        </Button>
      ),
    },
  ];

  useEffect(() => {
    firstLoadDone.current = true;
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === '/') {
        event.preventDefault();
        inputRef.current?.focus();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      firstLoadDone.current = false;
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (firstLoadDone.current && data) {
      const jsonData = JSON.parse(data);
      const isProjectStatus = Object.values(CheckMaskProject).includes(
        jsonData.event as CheckMaskProject,
      );
      const isDocumentStatus = Object.values(CheckMaskDocument).includes(
        jsonData.event as CheckMaskDocument,
      );

      if (isProjectStatus) {
        queryClient.invalidateQueries({ queryKey: ['checkMask'] });
      }

      if (isDocumentStatus) {
        queryClient.invalidateQueries({ queryKey: ['getListDocument'] });
      }

      if (jsonData.event === CheckMaskDocument.DocumentMaskingCompleted) {
        message.success(
          `The document ${jsonData.document_name} masking has been completed.`,
        );
      }
      if (jsonData.event === CheckMaskProject.ProjectMaskingCompleted) {
        message.success('The project masking has been completed.');
      }
      if (jsonData.event === CheckMaskDocument.DocumentMaskingFailed) {
        message.error('Document Masking Failed');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, sse]);

  return (
    <PageWrap>
      <CustomHeader>
        <div className="headerLeft">
          <div className="logo">
            <Link to="/" style={{ cursor: 'pointer' }}>
              <span className="titleLogo">Forecast Vista</span>
            </Link>
          </div>
        </div>

        <div className="headerRight">
          {isMobile ? (
            <Button
              type="primary"
              onClick={showDrawer}
              icon={<MenuOutlined />}
            />
          ) : (
            <>
              <Dropdown menu={{ items }} placement="bottomRight" arrow>
                <div className="userInfo">
                  <Avatar size={32} icon={<UserOutlined />} />
                  <div className="userName">
                    {user ? user.user_id : 'Account'}
                  </div>
                  <DownOutlined
                    style={{ marginLeft: '5px', color: '#ffffff' }}
                  />
                </div>
              </Dropdown>
            </>
          )}
        </div>
      </CustomHeader>
      <>
        <Layout>
          {!isMobile && (
            <Sider
              width={260}
              // className="site-layout-background"
              collapsible
              collapsed={collapsed}
              onCollapse={(value) => setCollapsed(value)}
              style={{
                overflow: 'auto',
                // height: '100vh',
                position: 'fixed',
                left: 0,
                top: '56px',
                bottom: '50px',
                backgroundColor: '#ffffff',
                borderRight: '1px solid rgba(240, 240, 240, 1)',
              }}
            >
              <MainMenu />
            </Sider>
          )}

          <Layout
            style={{
              padding: '0 24px 24px',
              marginLeft: ` ${isMobile ? 0 : !collapsed ? '260px' : '80px'}`,
              backgroundColor: '#ffffff',
              transition: 'margin 0.2s',
            }}
          >
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <Outlet />
            </Content>
          </Layout>
        </Layout>
        {isMobile && (
          <Drawer title="Mobile Menu" onClose={onClose} open={openDrawer}>
            {/* <MainMenu /> */}
            {/* <Navbar $isMobile>
              <li>
                <NavLink to={'/projects'} className="nav-link">
                  Projects List
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/projects/${project_id}/documents`}
                  className="nav-link"
                >
                  Documents
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/projects/${project_id}/risks`}
                  className="nav-link"
                >
                  Risk symptom/ Issue
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/projects/${project_id}/evaluate`}
                  className="nav-link"
                >
                  Evaluate
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/projects/${project_id}/dependency/dependency-list`}
                  className="nav-link"
                >
                  Dependency List
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/projects/${project_id}/dependency/confirmed-dependency`}
                  className="nav-link"
                >
                  Confirmed Dependency
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/projects/${project_id}/dependency/conflict-confirmation`}
                  className="nav-link"
                >
                  Conflict Confirmation
                </NavLink>
              </li>
            </Navbar> */}
            <MainMenu onChangeKey={() => setOpenDrawer(false)} />
          </Drawer>
        )}
      </>
    </PageWrap>
  );
};
