import useProject from '@/hooks/useProject';
import { Link } from 'react-router-dom';

const ProjectDetailPage = () => {
  useProject();
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f9f9f9',
      }}
    >
      <p
        style={{
          padding: '20px',
          fontSize: '1.2rem',
          backgroundColor: '#e0f7fa',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          textAlign: 'center',
        }}
      >
        Select a section (e.g., Overview, Documents) to view project details, or
        go back to the{' '}
        <Link
          to="/projects"
          style={{
            color: '#007acc',
            fontWeight: 'bold',
            textDecoration: 'none',
          }}
        >
          Projects
        </Link>{' '}
        page.
      </p>
    </div>
  );
};

export default ProjectDetailPage;
