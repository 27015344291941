import { useEffect, useRef, useState } from 'react';

const useSSE = (id: string | undefined, token: string | undefined) => {
  const [sse, setSse] = useState<EventSource | null>(null);
  const [data, setData] = useState<any>(null);
  const previousId = useRef<string | undefined>(undefined);

  useEffect(() => {
    // Check if ID and token are defined
    if (!id || !token) {
      console.error(
        'ID or token is undefined. SSE connection cannot be established.',
      );
      return;
    }

    // Check if the new ID is different from the previous ID, then create a new SSE
    if (id !== previousId.current) {
      console.log(`Creating SSE for ID: ${id}`);
      let connectionCount = parseInt(
        localStorage.getItem('sseConnectionCount') || '0',
        10,
      );
      connectionCount++;
      localStorage.setItem('sseConnectionCount', connectionCount.toString());
      console.log(`Total SSE connections: ${connectionCount} for ID: ${id}`);

      // Close the old connection if it exists
      if (sse) {
        console.log(`Closing existing SSE for ID: ${previousId.current}`);
        sse.close();
        setSse(null);
        setData(null);
      }

      // Create a new SSE connection
      const newSSE = new EventSource(
        `${process.env.REACT_APP_API_URL}/project/masking-status?project_id=${id}&token=${token}`,
      );
      setSse(newSSE);
      previousId.current = id;

      newSSE.onmessage = (event) => {
        setData(event.data);
      };

      newSSE.onerror = (error) => {
        console.error('SSE Error: ', error);
        newSSE.close();
        setSse(null);
      };
    }

    // Handle visibility change to manage SSE connections
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log('Document hidden', sse);
        // If the tab is not active, disconnect the SSE
        if (sse) {
          console.log(`Tab hidden, closing SSE for ID: ${id}`);
          sse.close();
          setSse(null);
        }
        setData(null);
      } else {
        // If the tab is active and not connected, create a new connection
        if (!sse) {
          console.log(`Tab visible, creating SSE for ID: ${id}`);
          const newSSE = new EventSource(
            `${process.env.REACT_APP_API_URL}/project/masking-status?project_id=${id}&token=${token}`,
          );
          setSse(newSSE);

          newSSE.onmessage = (event) => {
            setData(event.data);
          };

          newSSE.onerror = (error) => {
            console.error('SSE Error: ', error);
            newSSE.close();
            setSse(null);
            setData(null);
          };
        }
      }
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (sse) {
        console.log(`Cleaning up SSE for ID: ${id}`);
        sse.close();
        setSse(null);
        setData(null);
      }
    };
  }, [id, token, sse]);

  return { data, sse };
};

export default useSSE;
