import {
  App,
  Button,
  Input,
  InputRef,
  Radio,
  Space,
  Table,
  TableColumnType,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  // useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
// import { ButtonWrap } from './styles';
import dayjs from 'dayjs';
import {
  IDependency,
  IDependencyConflictSelect,
} from '@/types/dependency.type';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  confirmConflictService,
  getConflictDependencyListService,
} from '@/services/DependencyService';
import { SearchOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { FilterDropdownProps } from 'antd/es/table/interface';
import useProject from '@/hooks/useProject';
import { TitleGroup } from '@/components/TitleGroup';
import useCalculateDynamicHeight from '@/hooks/useCalculateDynamicHeight';

type DataIndex = keyof IDependency;

export const ConflictConfirmationPageView = () => {
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  const dataProjectDetail = useProject();
  const project_id = dataProjectDetail?.project_id;
  const [selectedDependencies, setSelectedDependencies] = useState<
    IDependencyConflictSelect[]
  >([]);
  const tableHeight = useCalculateDynamicHeight(250);
  // const [searchText, setSearchText] = useState('');
  // const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  // const [tableHeight, setTableHeight] = useState<number>(0);

  // useEffect(() => {
  //   const calculateTableHeight = () => {
  //     const viewportHeight = window.innerHeight;
  //     const offsetHeight = 300; // Adjust if you have other fixed elements (e.g., navbars)
  //     setTableHeight(viewportHeight - offsetHeight);
  //   };

  //   calculateTableHeight();
  //   window.addEventListener('resize', calculateTableHeight);

  //   return () => window.removeEventListener('resize', calculateTableHeight);
  // }, []);

  const {
    data: dataListConflictDependency,
    isFetching: loadingListConflictDependency,
  } = useQuery({
    queryKey: ['getConflictDependency', project_id],
    queryFn: () => getConflictDependencyListService(project_id as string),
    enabled: !!project_id, // Only enable the query if selectedProject is defined
  });

  const handleRadioChange = (dependencyId: string, conflictId: string) => {
    setSelectedDependencies((prevState) => {
      const newState = prevState.filter(
        (item) => item.conflict_id !== conflictId,
      );
      newState.push({ conflict_id: conflictId, dependency_id: dependencyId });
      return newState;
    });
  };

  const rowClassName = (record: IDependency) => {
    return selectedDependencies.some(
      (item) => item.conflict_id === record.conflict_id,
    )
      ? 'highlight-row'
      : '';
  };

  const onConfirm = useMutation({
    mutationFn: ({
      project_id,
      data,
    }: {
      project_id: string;
      data: IDependencyConflictSelect[];
    }) => confirmConflictService(project_id, data),
    onSuccess: () => {
      message.success(
        'Dependencies with conflicts have been successfully confirmed.',
      );
      queryClient.invalidateQueries({ queryKey: ['getConflictDependency'] });
      setSelectedDependencies([]);
    },
    onError: (err) => {
      message.error(err.message);
    },
  });

  const handleConfirm = () => {
    if (project_id) {
      onConfirm.mutate({
        project_id: project_id,
        data: selectedDependencies,
      });
    }
  };

  const flattenData =
    dataListConflictDependency &&
    dataListConflictDependency.data.flatMap((conflict) => conflict.items);

  const getRowSpan = (
    data: IDependency[],
    index: number,
    key: keyof IDependency,
  ) => {
    const current = data[index];
    const previous = data[index - 1];
    if (index === 0 || current[key] !== previous[key]) {
      const count = data
        .slice(index)
        .filter((item) => item[key] === current[key])?.length;
      return count;
    }
    return 0;
  };

  const conflictIdToIndex = useMemo(() => {
    const map: { [key: string]: number } = {};
    let currentIndex = 1;
    flattenData &&
      flattenData.forEach((item, index) => {
        if (
          index === 0 ||
          flattenData[index - 1].conflict_id !== item.conflict_id
        ) {
          item.conflict_id && (map[item.conflict_id] = currentIndex++);
        }
      });
    return map;
  }, [flattenData]);

  const handleSearch = (
    // selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    // dataIndex: DataIndex,
  ) => {
    confirm();
    // setSearchText(selectedKeys[0]);
    // setSearchedColumn(dataIndex);
  };

  const handleReset = (
    clearFilters: () => void,
    confirm: FilterDropdownProps['confirm'],
    close: () => void,
  ) => {
    clearFilters();
    // setSearchText('');
    confirm();
    close();
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
  ): TableColumnType<IDependency> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(
              // selectedKeys as string[],
              confirm,
              //  dataIndex
            )
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(
                // selectedKeys as string[],
                confirm,
                //  dataIndex
              )
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && handleReset(clearFilters, confirm, close)
            }
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      const cellValue = record[dataIndex];
      return cellValue
        ? cellValue
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase())
        : false;
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     <div dangerouslySetInnerHTML={{ __html: text }} />
    //   ),
  });

  const columns: ColumnsType<IDependency> = [
    {
      title: 'Conflict ID',
      dataIndex: 'conflict_id',
      // render: (value, row, index) => {
      //   const obj = {
      //     children: conflictIdToIndex[value],
      //     props: {
      //       rowSpan:
      //         flattenData && getRowSpan(flattenData, index, 'conflict_id'),
      //     },
      //   };
      //   return obj;
      // },
      // onCell: (record) => ({ colSpan: 2 })
      render: (value) => conflictIdToIndex[value],
      onCell: (record, index) => {
        // Ensure index is a number and getRowSpan returns a valid number
        // prettier-ignore
        const rowSpan =
          index !== undefined && flattenData
            ? (getRowSpan(flattenData, index, 'conflict_id') ?? 1)
            : 1;

        return {
          // colSpan: 2,
          rowSpan: rowSpan,
        };
      },
      // sorter: (a, b) => a.specific_id - b.specific_id,
      width: 110,
    },
    {
      title: 'Dependency Name',
      dataIndex: 'dependency_name',
      width: 366,
      ...getColumnSearchProps('dependency_name'),
      render: (value) => <div dangerouslySetInnerHTML={{ __html: value }} />,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 100,
      render: (value) => (
        <div
          style={{ textTransform: 'capitalize' }}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      ),
      // sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: 'Dependency Details',
      dataIndex: 'dependency_detail',
      width: 400,
      render: (value) => <div dangerouslySetInnerHTML={{ __html: value }} />,
      ...getColumnSearchProps('dependency_detail'),
    },
    {
      title: 'Data Source',
      dataIndex: 'data_source',
      width: 150,
      // ellipsis: true,
      render: (value) => (
        <div
          style={{
            textTransform: 'capitalize',
            width: '150px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          title={value}
        >
          {value}
        </div>
      ),
      ...getColumnSearchProps('data_source'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      render: (value) => (
        <span style={{ textTransform: 'capitalize' }}>{value}</span>
      ),
      // sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      width: 150,
      // render: (value) => (
      //   <span>
      //     {dayjs(value).isValid() ? dayjs(value).format('DD/MM/YYYY') : value}
      //   </span>
      // ),
      render: (value) => <div dangerouslySetInnerHTML={{ __html: value }} />,
      // sorter: (a, b) =>
      //   extractDateFromHTML(a.deadline) - extractDateFromHTML(b.deadline),
      // sorter: (a, b) => a.deadline.localeCompare(b.deadline),
    },
    {
      title: 'Last Updated Date',
      dataIndex: 'updated_at',
      width: 200,
      // render: (value) => (
      //   <span>
      //     {dayjs(value).isValid() ? dayjs(value).format('DD/MM/YYYY') : value}
      //   </span>
      // ),
      render: (value) => <span>{dayjs(value).format('YYYY/MM/DD')}</span>,
      // sorter: (a, b) => getUnixTime(a.updated_at) - getUnixTime(b.updated_at),
    },
    // {
    //   title: 'Third Party',
    //   dataIndex: 'Third_Party',
    //   width: 150,
    // },
    {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (_, record) => {
        return (
          <Radio.Group
            onChange={() =>
              record?.conflict_id &&
              handleRadioChange(record.dependency_id, record.conflict_id)
            }
            value={
              selectedDependencies.find(
                (item) => item.conflict_id === record.conflict_id,
              )?.dependency_id
            }
          >
            <Radio value={record.dependency_id}></Radio>
          </Radio.Group>
        );
      },
    },
  ];

  return (
    <>
      <TitleGroup
        title={`${dataProjectDetail?.project_code ? dataProjectDetail?.project_code : ''} -  Conflict Dependency List`}
        // subTitle="Forecast Vista generated dependencies are listed below. Review and approve appropriate dependencies to continue."
        button={
          <Space>
            <Button
              type="primary"
              onClick={handleConfirm}
              disabled={
                selectedDependencies && selectedDependencies?.length === 0
              }
            >
              Confirm
            </Button>
          </Space>
        }
      />
      <Table
        columns={columns}
        dataSource={flattenData as IDependency[]}
        rowKey={'dependency_id'}
        rowClassName={rowClassName}
        scroll={{ x: 1300, y: tableHeight }}
        loading={loadingListConflictDependency}
        pagination={false}
      />
      {/* <ButtonWrap style={{ marginTop: '16px' }}>
        <Button
          type="primary"
          onClick={handleConfirm}
          disabled={selectedDependencies && selectedDependencies?.length === 0}
        >
          Confirm
        </Button>
      </ButtonWrap> */}
    </>
  );
};
