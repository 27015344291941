export interface IDocument {
  file_name: string;
  file_type: string;
  status: string;
  document_category: string;
  created_at: string;
  document_id: string;
  processing_status: string;
}

export interface IDocumentRes {
  data: IDocument[];
}

export interface ICheckMaskRes {
  success: boolean;
  message: string;
  // "data": null,
  retry: boolean;
}

export enum CategoryType {
  DYNAMIC = 'dynamic',
  STATIC = 'static',
}

export enum DocumentType {
  VERIFY = 'analyzed',
  UN_VERIFY = 'to_analyze',
}

export interface IDocumentUpload {
  document_category: CategoryType;
  document_source?: string;
  file: any;
}

export interface UploadFileParams {
  formData: FormData;
  project_id: string;
  user_id: string;
  document_category: CategoryType;
  document_source?: string;
  force_update: boolean;
}

export enum CheckMaskProject {
  ProjectMaskingStarted = 'project_masking_started',
  ProjectMaskingCompleted = 'project_masking_completed',
}

export enum CheckMaskDocument {
  DocumentMaskingStarted = 'document_masking_started',
  DocumentMaskingCompleted = 'document_masking_completed',
  DocumentMaskingFailed = 'document_masking_failed',
}
